import React from "react"
import MetaTags from 'react-meta-tags';
import QrCode from 'qrcode.react';
import { graphql } from "gatsby"

export default function Component(props) {

  const downloadQRCode = (qrid) => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById(qrid);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = qrid + '.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  let linkForwarding = props.data.linksJson.link;
  let linkCurrentPage = 'https://www.bbsb.me/link/' + props.data.linksJson.secret;

  return (
    <div>
      <MetaTags>
        <title>Weiterleitung</title>
        <meta
          id="meta-description"
          name="description"
          content={props.data.linksJson.timeout + '; ' + linkForwarding}
          http-equiv="refresh"
        />
      </MetaTags>
      <table>
        <tr> Sie werden in {props.data.linksJson.timeout} Sekunden weitergeleitet: 
        <a href={props.data.linksJson.link} target="_blank" rel="noopener noreferrer">
        {props.data.linksJson.link}
      </a>
      </tr>
        <tr>Name: {props.data.linksJson.name}</tr>
        <tr>Description: {props.data.linksJson.description} </tr>
        <tr>Link: {props.data.linksJson.link}</tr>
        <tr>Secret: {props.data.linksJson.secret}</tr>
      </table>
      <table>
      <tr>
        <td>Link zur Weiterleitung ({linkForwarding})
        <p>
        <button type="button" onClick={() => downloadQRCode("qrForwarding")}>
          Download QR für Weiterleitung
        </button>
      </p></td>
        <td><QrCode value={linkForwarding} id="qrForwarding" /></td>
      </tr>
      <tr>
        <td>Link zu dieser Seite ({linkCurrentPage})
             <p>
        <button type="button" onClick={() => downloadQRCode("qrCurrentPage")}>
          Download QR für diese Seite
        </button>
      </p></td>
        <td>
          <QrCode value={linkCurrentPage} id="qrCurrentPage" />
          </td>
      </tr>
      </table>

      
    </div>
  );
}



export const query = graphql`
  query($id: String) {
    linksJson(id: { eq: $id }) {
        id
        name
        description
        link
        secret
        timeout
    }
  }
  `
